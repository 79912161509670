'use client';

import {ErrorBoundary as HighlightErrorBoundary} from '@highlight-run/next/client';

export default function GlobalError({error, reset}: {error: Error; reset: () => void}) {
  const isLocalhost = typeof window === 'object' && window.location.host?.startsWith('localhost');

  return (
    <HighlightErrorBoundary showDialog={!isLocalhost}>
      <html lang="en">
        <body className="flex flex-col justify-center items-center">
          <h2>Something went wrong!</h2>
          <button type="button" onClick={() => reset()}>
            Try again
          </button>
        </body>
      </html>
    </HighlightErrorBoundary>
  );
}
